import SeismIcon from './SeismIcon.vue'
import DangerousTransportIcon from './DangerousTransportIcon.vue'
import avalancheIcon from './avalancheIcon.vue'
import enginGuerreIcon from './enginGuerreIcon.vue'
import feuForetIcon from './feuForetIcon.vue'
import GasMaskIcon from './GasMaskIcon.vue'
import InnIcon from './InnIcon.vue'
import InstIcon from './InstIcon.vue'
import MvmtIcon from './MvmtIcon.vue'
import NucIcon from './NucIcon.vue'
import PhenomenesMeteorologiquesIcon from './PhenomenesMeteorologiquesIcon.vue'
import RadonIcon from './RadonIcon.vue'
import RuptureBarrageIcon from './RuptureBarrageIcon.vue'
import RetraitIcon from './RetraitIcon.vue'
import CavIcon from './CavIcon.vue'
import PolIcon from './PolIcon.vue'
import CanIcon from './CanIcon.vue'

export default {
  SeismIcon,
  DangerousTransportIcon,
  avalancheIcon,
  enginGuerreIcon,
  feuForetIcon,
  GasMaskIcon,
  InnIcon,
  InstIcon,
  MvmtIcon,
  NucIcon,
  PhenomenesMeteorologiquesIcon,
  RadonIcon,
  RuptureBarrageIcon,
  RetraitIcon,
  CavIcon,
  PolIcon,
  CanIcon,
}
